import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(private httpClient : HttpClient) { }
  getContractGeneral(customer_id, contract:number, keyword) {
    return this.httpClient.get(environment.api.getContractList_LF_V1 + '/'+ contract,{
      params:{
        customer_id:customer_id,
        keyword:keyword
      }
    }).map(data=>
    data);
  }
}
