import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "ema-roles-admin",
  templateUrl: "./roles-admin.component.html",
  styleUrls: ["./roles-admin.component.css"],
})
export class RolesAdminComponent implements OnInit {
  @Input() example: string;
  constructor() {}

  ngOnInit() {}
}
