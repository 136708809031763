import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { FullComponent } from "./layouts/full/full.component";
import { BlankComponent } from "./layouts/blank/blank.component";

import { AuthGuard } from "./auth.guard";
import { LoginV10Component } from "./login-v10/login-v10.component";
////import { MonitoringReportsComponent } from "./component/monitoring-reports-temp-url/monitoring-reports.component";
import { AccesoDenegadoComponent } from "./acceso-denegado/acceso-denegado.component";
//import { PruebaComponent } from "./component/monitoring-reports-temp-url/test.component";

//import { LoginV10Component } from './login-v10/login-v10.component';

export const Approutes: Routes = [
  {
    path: "",
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        redirectTo: "/login/" + localStorage.getItem("comp_login_token"),
        pathMatch: "full",
      },

      {
        path: "prospects",
        loadChildren: "./starter/starter.module#StarterModule",
        canActivate: [AuthGuard],
      },
      {
        path: "admin-prospect",
        loadChildren: "./component/prospecto/prospecto.module#ProspectoModule",
        canActivate: [AuthGuard],
      },
      {
        path: "quotation",
        loadChildren:
          "./component/quotation/quotation/quotation.module#QuotationModule",
        canActivate: [AuthGuard],
      },
      /*{
        path: 'customers-admin',
        loadChildren: './component/cliente/cliente.module#ClienteModule',
        canActivate: [AuthGuard]
      },*/
      {
        path: "client-list",
        loadChildren:
          "./component/client-list/client-list/client-list.module#ClientListModule",
        canActivate: [AuthGuard],
      },
      {
        path: "client-contracts",
        loadChildren:
          "./component/documents-admin/documents-admin/documents-admin.module#DocumentsAdminModule",
        canActivate: [AuthGuard],
      } /*
      {
        path: 'quotation-edit',
        loadChildren: './component/cotizacion/editarCotizacion.module#EditarCotizacionModule',
        canActivate: [AuthGuard]
      },

      {
        path: 'customer-configuration',
        loadChildren: './component/cliente/configuracion.cliente.module#ConfiguracionClienteModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'branches',
        loadChildren: './component/sucursal/sucursal.module#SucursalModule',
        canActivate: [AuthGuard]
      }*/,
      {
        path: "contract",
        loadChildren: "./component/contrato/contrato.module#ContratoModule",

        canActivate: [AuthGuard],
      },
      {
        path: "quotation-list",
        loadChildren:
          "./component/quotation/quotation-list/quotation-list/quotation-list.module#QuotationListModule",

        canActivate: [AuthGuard],
      },
      {
        path: "customers-branches-admin",
        loadChildren:
          "./component/customerbranchescrud/customerbranchescrud/customerbranchescrud.module#CustomerbranchescrudModule",
        canActivate: [AuthGuard],
      },
      {
        path: "formats",
        loadChildren:
          "./component/formats/formats/formats.module#FormatsModule",
        canActivate: [AuthGuard],
      },

      {
        path: "home",
        loadChildren:
          "./component/home-page/home-page/home-page.module#HomePageModule", //home-page/home-page/home-page.module#HomePageModule",
        canActivate: [AuthGuard],
      },
      /*,
      {
        path: 'vistacontrato',
        loadChildren: './component/contratovista/contratovista.module#ContratovistaModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'services-list',
        loadChildren: './component/mobile-application/services-client/services-client.module#ServicesClientModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'monitoring-types',
        loadChildren: './component/mobile-application/monitoring-types/monitoring-types.module#MonitoringTypesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'monitoring-options',
        loadChildren: './component/mobile-application/mon-control-roedores/services-option/services-option.module#ServicesOptionModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'material-used',
        loadChildren: './component/mobile-application/mon-control-roedores/material-used/material-used.module#MaterialUsedModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'report-cr',
        loadChildren: './component/mobile-application/mon-control-roedores/monitoring-report-cr/monitoring-report-cr.module#MonitoringReportCrModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'reports-printer',
        loadChildren: './component/mobile-application/reports-printer/reports-printer.module#ReportsPrinterModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'monitoring-option-2',
        loadChildren: './component/mobile-application/inspect-aplica-plaguicidas/services-option/services-option.module#ServicesOptionModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'inspection',
        loadChildren: './component/mobile-application/inspect-aplica-plaguicidas/inspeccion/inspeccion.module#InspeccionModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'report-iap',
        loadChildren: './component/mobile-application/inspect-aplica-plaguicidas/monitoring-report-iap/monitoring-report-insp.module#MonitoringReportInspModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'material-used-2',
        loadChildren: './component/mobile-application/inspect-aplica-plaguicidas/material-used/material-used.module#MaterialUsedModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'monitoring-option-3',
        loadChildren: './component/mobile-application/mon-control-insectos-vol/services-option/services-option.module#ServicesOptionModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'monitoring-civ',
        loadChildren: './component/mobile-application/mon-control-insectos-vol/monitoring-report-civ/monitoring-report-civ.module#MonitoringReportCivModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'catalogs-admin',
        loadChildren: './component/catalogsManager/pestTypesCatalog/pestTypesCatalog.module#PestTypesCatalogModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'arbol-coronario',
        loadChildren: './component/arbol-coronario/arbol-coronario/arbol-coronario.module#ArbolCoronarioModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'collection',
        loadChildren: './component/collection-module/collection-module/collection-module.module#CollectionModuleModule',
        canActivate: [AuthGuard]
      }*/ {
        path: "work-orders",
        loadChildren:
          "./component/contrato/contract-work-orders/contract-work-orders/contract-work-orders.module#ContractWorkOrdersModule",
        canActivate: [AuthGuard],
      },

      {
        path: "contract-branches-config",
        loadChildren:
          "./component/contrato/contract-branches-configuration/contract-branches-configuration/contract-branches-configuration.module#ContractBranchesConfigurationModule",
        canActivate: [AuthGuard],
      },
      {
        path: "contract-services-admin",
        loadChildren:
          "./component/contrato/contract-services-admin/contract-services-admin/contract-services-admin.module#ContractServicesAdminModule",
        canActivate: [AuthGuard],
      },
      {
        path: "associated-service-admin",
        loadChildren:
          "./component/contrato/associated-services-admin/associated-services-admin/associated-services-admin.module#AssociatedServicesAdminModule",
        canActivate: [AuthGuard],
      },
      /*,
      {
        path: 'devices-admin',
        loadChildren: './component/contrato/devices-management/devices-management/devices-management.module#DevicesManagementModule',
        canActivate: [AuthGuard]
      }
      ,
      {
        path: 'inventory-options',
        loadChildren: './component/inventory-admin/inventory-admin/inventory-admin/inventory-admin.module#InventoryAdminModule',
        canActivate: [AuthGuard]
      }      ,
      {
        path: 'inventory',
        loadChildren: './component/inventory-admin/inventory/inventory/inventory.module#InventoryModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'products-catalog',
        loadChildren: './component/catalogsManager/products/products/products.module#ProductsModule',
        canActivate: [AuthGuard]
      }*/
      {
        path: "customers-admin",
        loadChildren:
          "./component/customerscrud/customerscrud/customerscrud.module#CustomerscrudModule",
        canActivate: [AuthGuard],
      },
      {
        path: "configuration",
        loadChildren:
          "./component/configuration/configuration/configuration.module#ConfigurationModule",
        canActivate: [AuthGuard],
      },
      {
        path: "user-profile",
        loadChildren:
          "./component/user-administration/user-administration/user-administration.module#UserAdministrationModule",
        canActivate: [AuthGuard],
      },
      {
        path: "material-chemical-configuration",
        loadChildren:
          "./component/configuration/materialChemicalsConfiguration/materialchemical.module#MaterialChemicalModule",
        canActivate: [AuthGuard],
      },
      {
        path: "employee-list",
        loadChildren:
          "./component/employees/employees-list.module#EmployeesListModule",
        canActivate: [AuthGuard],
      },
      {
        path: "employee-admin",
        loadChildren:
          "./component/employees/employees-crud/employees-crud.module#EmployeesCrudModule",
        canActivate: [AuthGuard],
      },
      {
        path: "role-admin",
        loadChildren:
          "./component/system-admin/roles-admin/roles-admin/roles-admin.module#RolesAdminModule",
        canActivate: [AuthGuard],
      },
      {
        path: "user-admin",
        loadChildren:
          "./component/system-admin/user-admin/user-admin/user-admin.module#UserAdminModule",
        canActivate: [AuthGuard],
      },
      {
        path: "access-admin",
        loadChildren:
          "./component/system-admin/persmission-admin/persmission-admin/persmission-admin.module#PersmissionAdminModule",
        canActivate: [AuthGuard],
      },
      {
        path: "home-configuration",
        loadChildren:
          "./component/home-page/home-page-configuration/home-page-configuration.module#HomePageCModule",
        canActivate: [AuthGuard],
      },
      {
        path: "device-management",
        loadChildren:
          "./component/deviceManagement/deviceManagement.module#deviceManagementModule",
        canActivate: [AuthGuard],
      },
      {
        path: "predeterminated-services",
        loadChildren:
          "./component/configuration/PredeterminatedServices/predeterminatedservice.module#PredeterminatedServiceModule",
        canActivate: [AuthGuard],
      },
      {
        path: "predeterminated-services-config",
        loadChildren:
          "./component/configuration/PredeterminatedServices/predeterminatedservice-edit/predeterminatedservice-edit.module#PredeterminatedServiceEDITModules",
        canActivate: [AuthGuard],
      },
      {
        path: "collection",
        loadChildren:
          "./component/collection/collection.module#CollectionModule",
        canActivate: [AuthGuard],
      },
      {
        path: "delivery-notes",
        loadChildren:
          "./component/delivery-notes/delivery-notes.module#DeliveryNotesModule",
        canActivate: [AuthGuard],
      },
      {
        path: "system-configuration",
        loadChildren:
          "./AIONSystemsComponentsV1_0_0/system-configuration/system-configuration/system-configuration.module#SystemConfigurationModule",
        canActivate: [AuthGuard],
      },

      {
        path: "devices-configuration",
        loadChildren:
          "./component/contrato/devices-for-branches/devices-for-branches/devices-for-branches.module#DevicesForBranchesModule",
        canActivate: [AuthGuard],
      },
      {
        path: "formats-configuration",
        loadChildren:
          "./component/configuration-formats/configuration-formats/configuration-formats.module#ConfigurationFormatsModule",
        canActivate: [AuthGuard],
      },
      {
        path: "sales-list",
        loadChildren:
          "./component/sales-admin/sales-admin/sales-admin.module#SalesAdminModule",
        canActivate: [AuthGuard],
      },

      {
        path: "sales-admin",
        loadChildren:
          "./component/sales-admin/sales-admin-prospection/sales-admin-prospection/sales-admin-prospection.module#SalesAdminProspectionModule",
        canActivate: [AuthGuard],
      },
      {
        path: "devices-categories",
        loadChildren:
          "./component/configuration/devicesCategories/devicescategories.module#devicescategoriesModule",
        canActivate: [AuthGuard],
      },
      {
        path: "devices-elements",
        loadChildren:
          "./component/configuration/devicesCategories/devicesElements/deviceelements.module#deviceElemenetModules",
        canActivate: [AuthGuard],
      },
    ],
  },

  {
    path: "login/:companyToken",
    component: LoginV10Component,
  },
  {
    path: "acceso-denegado",
    component: AccesoDenegadoComponent,
  },
  /*{
    path: "monitoring-reports-customer/:tknAION/:wOTmp/:rTypTmp/:rITmp/:csITmp/:wOrFTmp",
    //loadChildren: "./component/monitoring-reports-temp-url/monitoring-reports-temp-url/monitoring-reports-temp-url.module@MonitoringReportsTempUrlModule"
    component:PruebaComponent,
    //canActivate: [AuthGuard]
  }*/
  {
    path: "monitoring-reports-customer/:tknAION/:wOTmp/:rTypTmp/:rITmp/:csITmp/:wOrFTmp",
    //component: PruebaComponent,
    loadChildren:
      "./component/monitoring-reports-temp-url/monitoring-reports-temp-url/monitoring-reports-temp-url.module#MonitoringReportsTempUrlModule",
    canActivate: [AuthGuard],
  },
  {
    //component\quotation\check-list\check-list\check-list.module.ts

    path: "quot-check-list/:customerId/:customerBranchId/:prospectId/:quotId/:userId/:sellerEmployeeId/:assignedEmployee/:prospectionID",
    //component: PruebaComponent,
    loadChildren:
      "./component/quotation/check-list/check-list/check-list.module#CheckListModule",
    canActivate: [AuthGuard],
  },
  {
    path: "meeting-detail",
    //component: PruebaComponent,
    loadChildren:
      "./component/meetingTempURL/meetingTemp.module#meetingTempModule",
    canActivate: [AuthGuard],
  },
  {
    path: "client-regis/:tknAION/:csITmp/:csbITmp",
    //component: PruebaComponent,
    loadChildren: "./component/newClient/newClient.module#newClientModule",
    canActivate: [AuthGuard],
  },
  {
    path: "**",
    redirectTo: "/login/AION",
  },
];
