import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams,
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
//import { Observable } from 'rxjs';
import { Observable, throwError, BehaviorSubject } from "rxjs";
import { MyserviceService } from "../services/myservice.service";
import { AuthService } from "../services/auth.service";

import { Router } from "@angular/router";
import { map, catchError, filter, take, switchMap } from "rxjs/operators";
import { isNull } from "util";
//import { AuthenticationService } from '@app/_services';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  token: string;
  private httpClient: HttpClient;

  constructor(private authService: AuthService, private router: Router) {}

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    //  const currentUser = this.authenticationService.currentUserValue;
   // //console.log("is in error handle intercept 1");
    this.token = sessionStorage.getItem("access_token_admin");
    /*//console.log('abajo')
      //console.log('aqui:' + token);*/
    //   //console.log('TOKEN:' + sessionStorage.getItem('access_token'));
    if (this.token != "temporal") {
      //   //console.log('entra')
      request = request.clone({
        setHeaders: {
          //Authorization: `Basic prueba`//`Basic ${currentUser.authdata}`
          token: sessionStorage.getItem("access_token_admin"),
          company_id:"1",//cambiarlo por el de sesion

          language_id: "1",
        },
      });
      //console.log("is intercept intercept 2 if" );
    }
    //console.log("is in intercept 3 despues de if");
    return next.handle(request).pipe(

      catchError((error: HttpErrorResponse) => {

        let refresh_token = sessionStorage.getItem("refresh_token_admin");
        if (isNull(this.token)) {
          ////console.log("is in error handle token is null ", this.router.url);
       // //console.log("--------refreshToken");
       // //console.log("Se va a login....");
          this.router.navigate(["/login"]);
          return throwError(error);
        }

        //No refreshToken, si se consume el api, de refreshtoken y login
        ////console.log("--------refreshToken");
        if (
          request.url.includes("refresh-token") ||
          request.url.includes("login") ||
          request.url.includes("logout")
        ) {
          //console.log("refreshToken entrando....");
          //en caso de que falle el refresh token, cerramos la sesion
          if (request.url.includes("refresh-token")) {
            // //console.log('fallo refreshToken,  logout');
            //console.log("refreshToken saliendo ....");
            this.authService.logout();
          }
          return throwError(error);
        }
        ////console.log("refreshToken error.status....");
        //Si el error es diferente de 409
        if (error.status != 409) {
          return throwError(error);
        }

        if (this.refreshTokenInProgress) {
          //todas las peticiones, hay que esperar a tener el nuevo token, cuando ya se esta onteniendo
          return this.refreshTokenSubject.pipe(
            filter((result: any) => {
              return result != null;
            }),
            take(1),
            switchMap(() => {
              return next.handle(this.addAuthenticationToken(request));
            })
          );
        } else {
          this.refreshTokenInProgress = true;
          this.refreshTokenSubject.next(null);

          return this.authService.refreshToken(this.token, refresh_token).pipe(
            switchMap((res: any) => {
              this.refreshTokenInProgress = false;
              this.refreshTokenSubject.next(res);
              return next.handle(this.addAuthenticationToken(request));
            }),
            catchError((err: any) => {
              this.refreshTokenInProgress = false;
              this.authService.logout();
              return throwError(error);
            })
          );
        }
      })
    );
  }
  addAuthenticationToken(request) {
    const accessToken = sessionStorage.getItem("access_token_admin");

    if (!accessToken) {
      //peticion continua
      return request;
    }
   // //console.log("addAuthenticationToken -------------");
    // se agrega, el nuevo token a la peticion
    return request.clone({
      setHeaders: {
        token: sessionStorage.getItem("access_token_admin"),
       // company_id:"1",
        language_id: "1",
      },
    });
  }
}
