import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private routes: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let nextPath = state.url;
    //console.log("AuthAuthGuard --> Revisar Acceso:", nextPath);
    //console.log("state:",state.url + " | " + nextPath);
    //console.log("next-->","/" + next.routeConfig.path);

    if (
      nextPath.includes("/login") ||
      nextPath.includes("/monitoring-reports-customer") ||
      nextPath.includes("/client-regis") ||
      nextPath.includes("/meeting-detail") ||
      nextPath.includes("/quot-check-list")
    ) {
      return true;
    }

    if (
      sessionStorage.getItem("access_token_admin") != null &&
      sessionStorage.getItem("refresh_token_admin") != null &&
      sessionStorage.getItem("menu_admin") != null
    ) {
      const menu = JSON.parse(sessionStorage.getItem("menu_admin"));

      for (let itemMenu of menu.accessURL) {
        if (nextPath === itemMenu) {
          return true;
        }
      }

      //console.log("AuthAuthGuard NO tiene permisos --> Login "+ nextPath);
      this.routes.navigate(["/acceso-denegado"]);
      return false;
    } else {
      console.log("AuthAuthGuard --> Login");
      this.routes.navigate([
        "/login/" + localStorage.getItem("comp_login_token"),
      ]);
      return false;
    }
  }

  /* canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let nextPath = state.url;


    if (this.isPublic(nextPath)) {

      return true; // Acceso público sin autenticación
    }

    if (this.withAuth()) {

      return this.haveLogin(nextPath);
    }
   // console.log("---------------Navigate to login");
    this.routes.navigate(["/login/"+localStorage.getItem("comp_login_token")]);
    return false;
  }
  private isPublic(url: string): boolean {

    const urlBase = url.split("?")[0];

    return (
      urlBase.includes("/login") || urlBase.includes("/monitoring-reports-customer")
    );
  }

  private withAuth(): boolean {
    console.error("withAuth");
    return (
      sessionStorage.getItem("access_token_admin") != null &&
      sessionStorage.getItem("refresh_token_admin") != null &&
      sessionStorage.getItem("menu_admin") != null
    );
  }

  private haveLogin(url: string): boolean {
    const menu = JSON.parse(sessionStorage.getItem("menu_admin"));

    for (let itemMenu of menu.accessURL) {
      if (url === itemMenu) {
        return true;
      }
    }

    //console.error("Acceso denegado");
    this.routes.navigate(["/acceso-denegado"]);
    return false;
  }*/
}
