import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { tools_options } from '../model/interface-system';

@Component({
  selector: 'ema-toolbar',
  templateUrl: './ema-toolbar.component.html',
  styleUrls: ['./ema-toolbar.component.scss']
})
export class EmaToolbarComponent implements OnInit, AfterViewInit, OnChanges {
  /*INPUT COMPONENTS
  AUTHOR: Adán Mateos Hernández
  09-dic-2022
    */
  @Input() tools: tools_options[]=[];
  @Input() enable_search_tool:boolean;
  @Input() extended_menu:boolean=false;
  @Input() navigation_paht: string="";
  //  @ViewChild('sPad',{static: true}) test_;



  constructor(protected _sanitizer: DomSanitizer) { }

  ngOnChanges(){
  }
  ngAfterViewInit(){

  }

  buidToolBar(){

  }
  ngOnInit() {

  }
  /*FUNCTIONS USED IN TOOLBAR */
  @Output() backToPg = new EventEmitter<any>();
  @Output() newRecord = new EventEmitter<[]>();
  @Output() deleteSelectedRecord = new EventEmitter<[]>();
  @Output() editSelectedRecord = new EventEmitter<[]>();
  @Output() saveRecord = new EventEmitter<[]>();
  @Output() function_search_=new EventEmitter<string>();
  text_:string;
  function_search(text){
    this.function_search_.emit(text);
  }

 /* safeHtml(html, id:number, show_:boolean) {
    if (show_){
      let resp_= this._sanitizer.bypassSecurityTrustHtml(html);
      //console.log(resp_);
      //div.children[0].setAttribute("click","i.function_(id, i)");
      return resp_;
    }
    return null;
  }

  stringToHTML(i:tools_options, show_:boolean, id:number, string_:string){
    if (show_){

      var div = document.getElementById("div_custom"+id);
      div.innerHTML = string_;
      //div.children[0].setAttribute("click","i.function_(id, i)");
      div.children[0].setAttribute("class", "input-text");
        console.log(div.children[0]);
      // Change this to div.childNodes to support multiple top-level nodes.
      return div.firstChild;
    }
    return "";
  }
*/


}
