//import { host_service } from './environment.prod';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const host_service = {
  host: "http://localhost:8080/ema-admin",
  host_victor: "https://a709ii5eo4.execute-api.us-east-2.amazonaws.com", //"https://5co1mrff1e.execute-api.us-east-2.amazonaws.com",
  host_victor2: "https://63rw20si6f.execute-api.us-east-2.amazonaws.com",
  host_victor3: "https://q6sq29b6ic.execute-api.us-east-2.amazonaws.com",
  path: "/v1/ema-system/",
  basePath: "/ema-admin/v1",
  admin: "/administration",
  host_super: "https://igd9lneez1.execute-api.us-east-1.amazonaws.com/emaAdmin",
  host_api_r: "https://igd9lneez1.execute-api.us-east-1.amazonaws.com",
  basePath_r: "/emaAdmin/api/v1",
};
export const environment = {
  production: false,
  ambiente: "DES-cloud",
  descrpcion_ambiente: "Test environment-cloud...",
  version_app: "V1.0.0",
  api: {
      menu:
        host_service.host + host_service.path + "administration/menu/ema-admin",
      menu_LF_V1: host_service.host_victor + host_service.basePath + "/menu",
      refreshToken:
        host_service.host + host_service.path + "both/ema-admin/refresh-token",
      logout_v1:
        "https://6bsc128mm6.execute-api.us-east-2.amazonaws.com/ema-authorization/v1/logout/",
      logout: host_service.host + host_service.path + "both/ema-admin/logout",
      login_LF_V1:
        "https://6bsc128mm6.execute-api.us-east-2.amazonaws.com/ema-authorization/v1/token",
      login: host_service.host + host_service.path + "both/login",
      get_catalogs_v1:
        host_service.host + host_service.path + "v1/ema-general/catalogs-v1",
      get_catalogs_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/ema-general/catalogs-v1",
      get_catalogs_LF_V2:
        host_service.host_victor3 +
        host_service.basePath +
        "/ema-general/catalogs-v2",
      prospectosPorCategoria:
        host_service.host + host_service.path + "v1/ema-admin/prospects",
      prospectosPorCategoria_LF_V1:
        host_service.host_victor + host_service.basePath + "/prospects",
      getQuotationListByProspectID:
        host_service.host + host_service.path + "quotations/",
      getQuotationListByProspectID_LF_V1:
        host_service.host_victor + host_service.basePath + "/quotations/",
      getQuotationByFilter:
        host_service.host_victor + host_service.basePath + "/quotationsbyfilter",
      ///////////CUSTOMERS
      getCustomersList: host_service.host + host_service.path + "customers/",
      customers_LF_V1: host_service.host + host_service.path + "customers/",
  
      getBranchesByCustomerID_LF_V1:
        host_service.host_victor + host_service.basePath + "/customers-branches",
      getIndustries:
        host_service.host + host_service.path + "ema-admin/catalogs/industries", /////NO se utilizará
      getCountries: host_service.host + host_service.path + "catalogs/countries",
      getCountries_LF_V1:
        host_service.host_victor + host_service.basePath + "/catalogs/countries",
      getStates: host_service.host + host_service.path + "catalogs/states",
      getStates_LF_V1:
        host_service.host_victor + host_service.basePath + "/catalogs/states",
      getCities: host_service.host + host_service.path + "catalogs/cities",
      getCities_LF_V1:
        host_service.host_victor + host_service.basePath + "/catalogs/cities",
      getCatalogActivitiesGroup:
        host_service.host + host_service.path + "catalog/activities-group",
      getCatalogActivitiesElements:
        host_service.host + host_service.path + "contract/services/elements-v1/", /////NO se utilizará
      //getCatalogActivitiesElements_LF_V1: host_service.host_victor + host_service.basePath + '/contract/services/elements-v1/',
      getContractList:
        host_service.host + host_service.path + "customers/contract/general-data",
  
      getContractBranches:
        host_service.host + host_service.path + "contract/customer-branches/", /////NO se utilizará
      getContractBranches_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/customer-branches/contract/",
      getContractGroupServices:
        host_service.host +
        host_service.path +
        "customers/contract/group-services/",
      getContractGroupServices_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/customers/contract/group-services",
      //NO SE USA
      addContractGroupServices:
        host_service.host +
        host_service.path +
        "customers/contract/group-services/", /////NO se utilizará
  
      deleteContractGroupServices:
        host_service.host +
        host_service.path +
        "customers/contract/group-services/",
      deleteContractGroupServices_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/customers/contract/group-services/",
      //NO SE USA
      getContractActivitiesElements:
        host_service.host +
        host_service.path +
        "contract/services/activities-elements/", /////NO se utilizará
  
      getContractActivitiesElementsV1:
        host_service.host + host_service.path + "contract/services/elements-v1/",
      getContractActivitiesElementsV1_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/contract/services/elements-v1/",
      //NO SE USA
      quotationStatusGet:
        host_service.host + host_service.path + "catalogs/quotations-status", /////NO se utilizará
      //EDITA TODA LA COTIZACION
      quotationCreation:
        host_service.host + host_service.path + "quotations-upsert",
  
      quotationCreation_LF_V1:
        host_service.host_victor + host_service.basePath + "/quotations-upsert",
      deleteQuotation_LF_V1:
        host_service.host_victor + host_service.basePath + "/quotations",
      //CONFIGURACION DE LAS ACTIVIDADES
      readactivitiesConfiguration:
        host_service.host_victor +
        host_service.basePath +
        "/contract/services/activities/configuration",
      //PARTE DE SERVICIOS A COTIZAR
      createQuotationGroupService:
        host_service.host +
        host_service.path +
        "customers/quotation-v1/group-services",
      QuotationGroupService_LAMBDA_V1:
        host_service.host_victor +
        host_service.basePath +
        "/customers/quotation-v1/group-services",
  
      deleteQuotationGroupService:
        host_service.host +
        host_service.path +
        "customers/quotation-v1/group-services",
      readQuotationGroupService:
        host_service.host +
        host_service.path +
        "customers/quotation-v1/group-services/",
  
      //LAS ACTIVIDADES QUE SE REALIZAN EN LOS GRUPOS DE SERVICIOS DENTRO DE SERVICIOS A COTIZAR CUANDO LE EDITAS
      createQuotationActivities:
        host_service.host +
        host_service.path +
        "customers/quotation-v1/activities",
      QuotationActivities_LAMBDA_V1:
        host_service.host_victor +
        host_service.basePath +
        "/customers/quotation-v1/activities",
      readQuotationActivities:
        host_service.host +
        host_service.path +
        "customers/quotation-v1/activities",
  
      employeesGet: host_service.host + host_service.path + "v1/employees/",
      Employees_LAMBDA_V1:
        host_service.host_victor + host_service.basePath + "/employees",
  
      //TIPOS DE VENTA
      getQuotationHasSalesType:
        host_service.host + host_service.path + "quotation/sales-type",
      QuotationHasSalesTypes_LAMBDA_V1:
        host_service.host_victor +
        host_service.basePath +
        "/quotation/sales-type",
  
      deleteQuotationHasSalesType:
        host_service.host + host_service.path + "quotation/sales-type",
  
      createQuotationHasSalesType:
        host_service.host + host_service.path + "quotation/sales-type",
  
      //LUGARES DEL SERVICIO
      QuotationHasServicePlace_LAMBDA_V1:
        host_service.host_victor +
        host_service.basePath +
        "/quotation/service-places",
      getQuotationHasServicePlaces:
        host_service.host + host_service.path + "quotation/service-places",
      deleteQuotationHasServicePlaces:
        host_service.host + host_service.path + "quotation/service-places",
      createQuotationHasServicePlaces:
        host_service.host + host_service.path + "quotation/service-places",
  
      //FRECUENCIA DEL SERVICIO
      Quotation_Service_Frecuency_LAMBDA_V1:
        host_service.host_victor +
        host_service.basePath +
        "/quotation/service-frequency",
      getQuotationHasServiceFrequency:
        host_service.host + host_service.path + "quotation/service-frequency",
  
      deleteQuotationHasServiceFrequency:
        host_service.host + host_service.path + "quotation/service-frequency",
  
      createQuotationHasServiceFrequency:
        host_service.host + host_service.path + "quotation/service-frequency",
  
      //FORMA DE PAGO
      Quotation_Payment_Form_LAMBDA_V1:
        host_service.host_victor +
        host_service.basePath +
        "/quotation/payment-form",
      getQuotationHasPaymentForm:
        host_service.host + host_service.path + "quotation/payment-form",
  
      deleteQuotationHasPaymentForm:
        host_service.host + host_service.path + "quotation/payment-form",
  
      createQuotationHasPaymentForm:
        host_service.host + host_service.path + "quotation/payment-form",
  
      //DIAS DE CREDITO
      Quotation_Credit_Days_LAMBDA_V1:
        host_service.host_victor +
        host_service.basePath +
        "/quotation/credit-days",
      getQuotationHasCreditDays:
        host_service.host + host_service.path + "quotation/credit-days",
  
      deleteQuotationHasCreditDays:
        host_service.host + host_service.path + "quotation/credit-days",
  
      createQuotationHasCreditDays:
        host_service.host + host_service.path + "quotation/credit-days",
  
      //DISPOSITIVOS
      Quotation_Devices_LAMBDA_V1:
        host_service.host_victor +
        host_service.basePath +
        "/customers/quotation-v1/devices",
      getQuotationHasDevices:
        host_service.host + host_service.path + "customers/quotation-v1/devices",
  
      deleteQuotationHasDevices:
        host_service.host + host_service.path + "customers/quotation-v1/devices",
  
      createQuotationHasDevices:
        host_service.host + host_service.path + "customers/quotation-v1/devices",
  
      get_customer_data: host_service.host + host_service.path + "customers/",
      get_associated_service:
        host_service.host +
        host_service.path +
        "contract/catalogs/associated-services-types",
      get_contract_status:
        host_service.host +
        host_service.path +
        "customers/contract/general-data/status",
      get_contract_associated_service:
        host_service.host + host_service.path + "contract/associated-services/",
      upsert_contract_associated_service:
        host_service.host +
        host_service.path +
        "customers/contract/associated-service",
      upsert_contract_associated_service_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/contract/associated-services",
      get_supervisor_and_technical_list:
        host_service.host + host_service.path + "employees/data/",
      get_supervisor_and_technical_list_LF_V1:
        host_service.host_victor + host_service.basePath + "/employees/data",
      getassociated_service_by_group_service:
        host_service.host + host_service.path + "contract/associated-services",
      getassociated_service_by_group_service_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/contract/associated-services/",
      //----------------------HASTA AQUÍ
      get_g_s_for_sociated_service:
        host_service.host +
        host_service.path +
        "customers/contract/group-services-associated-service/",
      get_g_s_for_sociated_service_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/customers/contract/group-services-associated-service/",
      get_service_scheduling:
        host_service.host +
        host_service.path +
        "customers/contract/service-scheduling/",
      get_service_scheduling_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/customers/contract/service-scheduling",
      get_wo_employees:
        host_service.host + host_service.path + "contract/work-orders/employees",
      get_wo_employees_data:
        host_service.host + host_service.path + "work-orders/employees/",
      get_wo_employees_data_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/work-orders/employees",
      post_wo_employees_data:
        host_service.host + host_service.path + "contract/work-orders/employees",
      post_wo_employees_data_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/work-orders/employees",
      delete_wo_employees_data_LF_V1:
        host_service.host_victor2 +
        host_service.basePath +
        "/work-order-employee",
      put_wo_supervisor_data:
        host_service.host_victor +
        host_service.path +
        "customers/contract/service-work-order",
      delete_wo_employees_data:
        host_service.host_victor +
        host_service.path +
        "contract/work-orders/employees/",
      add_group_services:
        host_service.host +
        host_service.path +
        "customers/contract/group-services",
      add_group_services_Lambda_V1:
        host_service.host_victor +
        host_service.basePath +
        "/customers/contract/group-services",
      update_contract_general_data:
        host_service.host + host_service.path + "customers/contract/general-data", //ya en lambda
      create_contract_general_data:
        host_service.host + host_service.path + "customers/contract/general-data", //ya en lambda
      create_schedulle:
        host_service.host +
        host_service.path +
        "customers/contract/work-order-schedulling",
      create_schedulle_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/customers/contract/service-scheduling",
      create_schedulle_eventual_service:
        host_service.host +
        host_service.path +
        "customers/contract/service-scheduling/eventual-service",
      create_schedulle_individual_service:
        host_service.host +
        host_service.path +
        "customers/contract/individual-work-order-schedulling",
      create_schedulle_individual_service_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/customers/contract/individual-work-order-schedulling",
      add_customer_branches_contract:
        host_service.host + host_service.path + "contract/customer-branches",
      add_customer_branches_contract_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/customer-branches/contract",
      add_activity_to_contract:
        host_service.host + host_service.path + "contract/services/elements",
      add_activity_to_contract_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/contract/services-v1/activities",
      get_activity_to_contract:
        host_service.host + host_service.path + "contract/services-v1/activities",
      get_activity_to_contract_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/contract/services-v1/activities",
      delete_activity_to_contract:
        host_service.host + host_service.path + "contract/services-v1/activities",
      delete_activity_to_contract_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/contract/services-v1/activities",
      /////////////AMH CLIENT V1
      client_service:
        host_service.host_victor + host_service.basePath + "/customers",
      branches_client_service:
        host_service.host_victor + host_service.basePath + "/customers-branches",
      branches_client_all:
        host_service.host_victor2 +
        host_service.basePath +
        "/customers-branches/all ",
      getZones:
        host_service.host_victor + host_service.basePath + "/catalogs/zones",
      getRoutes:
        host_service.host_victor + host_service.basePath + "/catalogs/routes",
      //Contract
      updateGroup_service:
        host_service.host_victor +
        host_service.basePath +
        "/customers/contract/update/group-services",
      getContractList_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/customers/contract/general-data",
      upsertContract_LF_V1:
        host_service.host_victor +
        host_service.basePath +
        "/customers/contract/general-data",
      GetTechnical_LF_V1:
        host_service.host_victor + host_service.basePath + "/work-order/tecnical",
  
      //////devices
      devices_service:
        host_service.host_victor + host_service.basePath + "/devices",
      ////file maps
      file_maps_service:
        host_service.host_victor +
        host_service.basePath +
        "/customers/contract/maps",
      //USUARIOS
      user_service: host_service.host_victor + host_service.basePath + "/user",
      //MATERIALES QUIMICOS
      material_chemical:
        host_service.host_victor + host_service.basePath + "/materials-chemical",
      //Compania
      company: host_service.host_victor + host_service.basePath + "/company",
      companyBranches:
        host_service.host_victor + host_service.basePath + "/company/branches",
      upseremployee:
        host_service.host_victor2 + host_service.basePath + "/employees",
      prospectToClient:
        host_service.host_victor2 + host_service.basePath + "/prospect-client",
      quotationprospectToClient:
        host_service.host_victor2 +
        host_service.basePath +
        "/quotation/prospect-client",
  
      //Usuarios Administracion
      UserAdmin:
        host_service.host_victor2 +
        host_service.basePath +
        host_service.admin +
        "/user",
      //UserRoles Administracion
      UserRolesAdmin:
        host_service.host_victor2 +
        host_service.basePath +
        host_service.admin +
        "/user/roles",
      //Roles Administracion
      RolesAdmin:
        host_service.host_victor2 +
        host_service.basePath +
        host_service.admin +
        "/roles",
      //RolesPages Administracion
      RolesPagesAdmin:
        host_service.host_victor2 +
        host_service.basePath +
        host_service.admin +
        "/roles/pages",
      //RolesLabels Administracion
      RolesLabelsAdmin:
        host_service.host_victor2 +
        host_service.basePath +
        host_service.admin +
        "/roles/labels",
      //Pages Administracion
      PagesAdmin:
        host_service.host_victor2 +
        host_service.basePath +
        host_service.admin +
        "/pages",
      //PagesLAbels Administracion
      PagesLabelsAdmin:
        host_service.host_victor2 +
        host_service.basePath +
        host_service.admin +
        "/pages/labels",
  
      //Licencenses EMployees
      driverLicenses:
        host_service.host_victor2 + host_service.basePath + "/drivers",
      //EMployees documents
      documentEmployees:
        host_service.host_victor2 +
        host_service.basePath +
        "/employees/documents",
      QuotationProspectClientInfo:
        host_service.host_victor2 +
        host_service.basePath +
        "/quotation/prospect/client/info/",
  
      //device monitoring
      deviceMonitoring:
        host_service.host_victor2 + host_service.basePath + "/device-monitoring",
      deviceManagement:
        host_service.host_victor2 + host_service.basePath + "/device-management",
      //Insertar y Eliminar actividades de un servicio predeterminado
      activitiesConfiguration:
        host_service.host_victor2 +
        host_service.basePath +
        "/services/activities/configuration",
      payment_form_contract:
        host_service.host_victor2 +
        host_service.basePath +
        "/contract/payment-form",
      //Eliminar una workorder individual
      deleteWorkOrder:
        host_service.host_victor2 + host_service.basePath + "/work-order",
      //Para consultar el estado de las work order y verificar que se pueda borrar un associated service
      checkStatusWorkOrder:
        host_service.host_victor2 + host_service.basePath + "/work-order/status",
      //Para eliminar el servicio asociado
      deleteAssocService:
        host_service.host_victor2 +
        host_service.basePath +
        "/contract/associated-services/",
      //obtener el id del eventual
      eventualID:
        host_service.host_victor2 + host_service.basePath + "/customers/eventual",
      //Actualizar un dispositivo
      updateDevice:
        host_service.host_victor2 + host_service.basePath + "/devices/update",
  
      //Obtener detalles generales del servicio asociado
      servicesDetail:
        host_service.host_victor2 +
        host_service.basePath +
        "/customers/contract/service-scheduling/detail",
      workOrdersInformation:
        host_service.host_victor2 +
        host_service.basePath +
        "/collections/schedule",
      workOrderreport:
        host_service.host_victor2 + host_service.basePath + "/work-order/report/",
      salesDocumentHasServices:
        host_service.host_victor2 + host_service.basePath + "/sales-doc/services",
      salesDocument:
        host_service.host_victor2 + host_service.basePath + "/sales-doc",
      workOrderBilled:
        host_service.host_victor2 + host_service.basePath + "/work-order/billed",
      notificationsNewPassword:
        "https://yskfrn7xul.execute-api.us-east-2.amazonaws.com/" +
        "test/send/notifications-new-password",
      checkUserInformation:
        host_service.host_victor2 + host_service.basePath + +"/user/exist",
      eventualServicesForBranch:
        host_service.host_victor2 +
        host_service.basePath +
        "/work-orders/eventual/branch",
      eventualServices:
        host_service.host_victor2 +
        host_service.basePath +
        "/work-orders/eventual",
      service_report: host_service.host_super + "/api/v1/serviceReport",
      work_order_sup: host_service.host_super + "/dev/workOrder",
  
      tempUrl: host_service.host_victor2 + host_service.basePath + "/temp-url",
      tokenTemp:
        "https://6bsc128mm6.execute-api.us-east-2.amazonaws.com/ema-authorization/v1/tokenTemp",
      contract_ind:
        host_service.host_victor2 +
        host_service.basePath +
        "/customers/contract/individual",
      /////devices api
      devices_monitoring_get:
        host_service.host_api_r +
        host_service.basePath_r +
        "/service-report/monitoring-devices",
      devices_monitoring_upsert:
        host_service.host_api_r +
        host_service.basePath_r +
        "/serviceReport/deviceMonitoring",
      areas_service:
        host_service.host_victor2 + host_service.basePath + "/service/area",
  
      ////system configuration
      system_config_user_service:
        host_service.host_victor2 + host_service.basePath + "/user",
      system_config_companies_service:
        host_service.host_victor3 + host_service.basePath + "/companies",
      system_config_comany_branches_service:
        host_service.host_victor3 + host_service.basePath + "/companies/branch",
  
      system_config_roles_service:
        host_service.host_victor2 +
        host_service.basePath +
        "/administration/roles",
      system_config_roles_labels_service:
        host_service.host_victor2 +
        host_service.basePath +
        "/administration/roles/labels",
      system_config_roles_pages_service:
        host_service.host_victor2 +
        host_service.basePath +
        "/administration/roles/pages",
      system_config_pages_service:
        host_service.host_victor2 +
        host_service.basePath +
        "/administration/pages",
      system_config_pages_labels_service:
        host_service.host_victor2 +
        host_service.basePath +
        "/administration/pages/labels",
      prospections_services:
        host_service.host_victor3 + host_service.basePath + "/prospections",
  
        inspectionService:host_service.host_victor3+host_service.basePath+"service-report/inspection",
      prospection_letter_service:
        host_service.host_victor3 +
        host_service.basePath +
        "/prospections/letter",
      prospection_meeting_service:
        host_service.host_victor3 +
        host_service.basePath +
        "/prospections/meeting",
      email_attachment_service:
        host_service.host_victor3 + host_service.basePath + "/send/email",
        companySecurity:host_service.host_victor2+host_service.basePath+"/security/company"
    },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
