import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/shareReplay';

@Injectable()
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private router: Router) { }
    //Este es el que se debe modificar
    login(username: string, password: string) {
        sessionStorage.setItem('access_token_admin' , 'temporal');
        const params = new HttpParams({
          fromObject: {
            user_email: username,
            user_password: password,
            language_id: '1'
          }
        });
        return this.httpClient.post(environment.api.login_LF_V1,
        params,
        {
          headers: new HttpHeaders({
               'Content-Type':  'application/x-www-form-urlencoded',
             })
        }).do((authResult: any) => {
          console.log("---> " + username);
          localStorage.setItem("company_id", authResult.data[0].company_id+"");
          localStorage.setItem('session_active_admin', 'active');
          //sessionStorage.setItem('access_token_admin', authResult.data.access_token);
          //sessionStorage.setItem('refresh_token_admin', authResult.data.refresh_token);
          sessionStorage.setItem('access_token_admin', authResult.token);
          sessionStorage.setItem('refresh_token_admin', authResult.refresh_token);
          //localStorage.setItem('user_id_profile_admin', authResult.data.user_data.user_id);
          localStorage.setItem('user_id_profile_admin', authResult.data[0].users_id);
          localStorage.setItem('user_name_profile_admin', username);
          localStorage.setItem('user_role_admin', authResult.data[0].role_id);
          //localStorage.setItem('branch_office_name_admin', authResult.data.branch_office.name);
          //localStorage.setItem('company_name_admin', authResult.data.branch_office.company_name);
        })
        .shareReplay();
      }

  refreshToken(access_token: string, refresh_token: string) {
    // console.log("refreshToken session.....");
     //session_active == 'null' ? "nulo" : 0
     if (localStorage.getItem("session_active_admin") == "inactive" || localStorage.getItem("session_active_admin") == 'null'){
       this.logout();
      // console.log("Session cerrada en otra pestaña");
       return;
     }else if (localStorage.getItem("session_active_admin") == "active"){
       //console.log("Session abierta...");
     }
     const params = new HttpParams({
       fromObject: {
         token: access_token,
         refresh_token: refresh_token
       }
     });
     return this.httpClient.post(environment.api.refreshToken,
     params,
     {
       headers: new HttpHeaders({
            'Content-Type':  'application/x-www-form-urlencoded',
          })
     }).do((authResult: any) => {
       sessionStorage.setItem('access_token_admin', authResult.data.access_token);
       sessionStorage.setItem('refresh_token_admin', authResult.data.refresh_token);
     })
     .shareReplay();
   }

   logout() {
    //console.log("user id:: " + localStorage.getItem('customer'));
     // remove user from local storage to log user out
     const params = new HttpParams({
      fromObject: {
        user_id: localStorage.getItem('user_id_profile_admin'),
        token:sessionStorage.getItem("access_token_admin")
      }
    });
    return this.httpClient.post(environment.api.logout_v1+localStorage.getItem('user_id_profile_admin'),
              params,
              {
               headers: new HttpHeaders({
                'Content-Type':  'application/x-www-form-urlencoded',
               'token':sessionStorage.getItem("access_token_admin"),
              })}).do((data) => {
                  sessionStorage.removeItem('access_token_admin');
                  sessionStorage.removeItem('refresh_token_admin');
                  localStorage.removeItem('user_admin');
                  localStorage.removeItem('branch_office_name_admin');
                  localStorage.removeItem('menu_admin');
                  localStorage.removeItem('submenu_admin');
                  localStorage.removeItem('company1_id_admin');
                  localStorage.removeItem('company_branch_id_admin');
                  //Se elimina menu
                  localStorage.removeItem('titleMenu_admin');

                  console.log("clearing all data.... " + localStorage.getItem("comp_login_token"));
                  this.router.navigate(['/login/'+ localStorage.getItem("comp_login_token")]);
                  sessionStorage.clear();
                  localStorage.clear();
              } );
  }
  refreshTokenObselete(access_token: string, refresh_token: string) {

    const params = new HttpParams({
      fromObject: {
        token: access_token,
        refresh_token: refresh_token
      }
    });
    return this.httpClient.post('http://adec-authorization.us-east-2.elasticbeanstalk.com/v1/ema-system/both/refresh-token',
      params,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        })
      }).do((authResult: any) => {
        sessionStorage.setItem('access_token', authResult.data.access_token);
        sessionStorage.setItem('refresh_token', authResult.data.refresh_token);
      })
      .shareReplay();
  }

  logoutObsolete() {
    // remove user from local storage to log user out
    //localStorage.getItem('user_id')
    //console.log(localStorage.getItem('user_id'));
    const params = new HttpParams({
      fromObject: {
        user_id: '1'
      }
    });
    return this.httpClient.post('http://adec-authorization.us-east-2.elasticbeanstalk.com/v1/ema-system/both/logout',
      params,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        })
      }).do((data) => {
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('refresh_token');
        localStorage.removeItem('customer');
        localStorage.removeItem('customerBranch');
        localStorage.removeItem('user');
        localStorage.removeItem('branch_office_name');
        localStorage.removeItem('menu');
        localStorage.removeItem('submenu');
        localStorage.removeItem('esEmpleado');
        localStorage.removeItem('company1_id');
        localStorage.removeItem('company_branch_id');
        //Se elimina menu
        localStorage.removeItem('titleMenu');
        this.router.navigate(['/login']);
      });
  }

  obtenerMenu() {
    let user_id= localStorage.getItem('user_id_profile_admin');
    let rol =localStorage.getItem('user_role_admin');
    return this.httpClient.get(environment.api.menu_LF_V1 ,{
      params:{
        role_id:rol,
        user_id:user_id,
      }
    }).do((menuResp: any) => {
      console.log('Todo el menu:', menuResp.body);
      let accessURL = [];
      let defaultURL = '';
      //filtrar sidebar
      let menuTemp = menuResp.body.filter(item => {
      //console.log("Menutemp"+menuTemp);//no se llena
      item.submenu = item.submenu.filter(subitem => {
        accessURL.push(subitem.path); //si se llena
          //  console.log("MENU--------------------");
          //console.log("item.submenu"+item.submenu);
          if(subitem.is_default_page == 1) {
            localStorage.setItem('menu_admin', item.menu_id);
            localStorage.setItem('submenu_admin', subitem.menu_id);
            defaultURL = subitem.path; //si se llena
         }
         return (subitem.is_in_menu == 1);
        });
       return (item.is_in_menu == 1);
      });
      //mapear sidebar
      menuTemp.map(item =>{
        item.title = item.module;
        item.class = item.class_;
        //console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>");
       // console.log(item.submenu); //si se llena
        item.submenu.map(subitem => {
          subitem.class = subitem.class_;
          subitem.submenu = [];
         // console.log(subitem);
          return subitem;

        });
        return item;
      });
      let menu = {
        data: menuTemp,
        accessURL : accessURL,
        defaultURL: defaultURL
      };
      //console.log('Filtro menu sidebar:', menuTemp);
      //console.log('---> Url access:', accessURL);
      //console.log(JSON.stringify(menu));
      sessionStorage.setItem('menu_admin', JSON.stringify(menu));
    });
  }
  loginObsolete(username: string, password: string) {
    // console.log("--------login");
     sessionStorage.setItem('access_token_admin', 'temporal');
     const params = new HttpParams({
       fromObject: {
         user_email: username,
         user_password: password,
         language_id: '1'
       }
     });

     return this.httpClient.post(environment.api.login,
       params,
       {
         headers: new HttpHeaders({
           'Content-Type': 'application/x-www-form-urlencoded',
         })
       }).do((authResult: any) => {

           localStorage.setItem('session_active_admin', 'active');
           sessionStorage.setItem('access_token_admin', authResult.data.access_token);
           sessionStorage.setItem('refresh_token_admin', authResult.data.refresh_token);
           localStorage.setItem('user_id_profile_admin', authResult.data.user_data.user_id);
           localStorage.setItem('user_name_profile_admin', username);
           localStorage.setItem('user_role_admin', authResult.data.user_data.user_detail.role);
           localStorage.setItem('branch_office_name_admin', authResult.data.branch_office.name);
           localStorage.setItem('company_name_admin', authResult.data.branch_office.company_name);
           //this.router.navigate(['/prospect']);
       })
       .shareReplay();
   }



   getCompanyByToken(
    company_token
  ) {
    sessionStorage.setItem('access_token_admin' , 'temporal');
    console.log("Ejecutado")
    return this.httpClient.get(environment.api.companySecurity, {
        params: {
          company_token: company_token.toString(),
         
        },
        
      })
      .map((data) => data);
  }
}
