import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ema-user-admin",
  templateUrl: "./user-admin.component.html",
  styleUrls: ["./user-admin.component.css"],
})
export class UserAdminComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
