export class listForUploading {
    id: number;
    file_id: number;
    file_name: string;
    file_status: number;
    file_hidden: boolean;
    file_status_str: string;
    zone_name: string;
    route_name: string;
  }
  export class fileDataConnection{
    codigo_catalogo: number;
  configuracion_id: number;
  creation_date: string;
  creation_time: string;
  creation_user_id: number;
  directorio_local: string;
  directorio_remoto: string;
  hospital_id: number;
  known_hosts: string;
  modo_acceso_id: number;
  nombre_catalogo: string;
  nombre_conexion: string;
  nomenclatura: string;
  password: string;
  puerto: number;
  server_ip_address: string;
  server_type_id: string;
  status: number;
  strict_host_key_checking: string;
  tipo_conexion: string;
  username: string;
  
  }
  export class listUploadFile{
    data:{};
  }
  export class uploadFileModel {
    resume: number;
    hospital_id: number;
    paciente_id: number;////
    evento_id: number;
    angiografia_id: number;
    angioplastia_id: number;
    fecha_resumen: string;
    hora_resumen: string;
    nombre_archivo_original: string;
    nombre_archivo: string;////
    descripcion_archivo: string;
    tipo_archivo:string;
    tamaño: number;
    creation_user_id: number;
    creation_username: string;
    creation_date: string;
    creation_time: string;
    modification_user_id: number;
    modification_username: string;
    modification_date: string;
    modification_time: string;
    status: number;
    file:string;
  }