import { Component, AfterViewInit, EventEmitter, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { local } from "d3";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { userModelV1 } from "src/app/component/models/userModelV1";
import { UserAdministrationComponent } from "src/app/component/user-administration/user-administration.component";
import { AuthService } from "src/app/services/auth.service";
import { UserProfileService } from "src/app/services/user-profile.service";
import * as AWS from "aws-sdk";
import { bucket } from "src/app/AIONSystemsComponentsV1_0_0/bucket/bucket";
declare var $: any;

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  //////////////DATOS DE USUARIO
  username: string;
  branch_office_name: string;
  user_photo: any;
  constructor(
    private routes: Router,
    private modalService: NgbModal,
    private service: AuthService,
    private user_service: UserProfileService,
    private sanitizer: DomSanitizer
  ) {}

  logout(e) {
    e.stopPropagation();
    e.preventDefault();
    this.service.logout().subscribe();
    return false;
  }
  user_id: number;
  ngOnInit() {
    this.user_id = Number(localStorage.getItem("user_id"));
    this.user_photo = null;
    this.username = localStorage.getItem("user_name_profile_admin");
    this.getUserINFO(this.user_id);
    if (this.username != null || this.username == "") {
      if (this.username.length > 23) {
        this.username = this.username.substring(0, 23) + "...";
      } else {
        this.username;
      }
    }
    this.branch_office_name = localStorage.getItem("branch_office_name");
  }
  user_data = {} as userModelV1;
  getUserINFO(user_id) {
    this.user_service.getUserDataByID(user_id).subscribe((res: any) => {
      if (res.status == 200) {
        this.user_data = res.data[0];
        this.user_data.cellphone = res.data[0].phone;
        this.user_data.photo = res.data[0].photo;

        console.log(this.user_data);
        localStorage.setItem("Profile_image", this.user_data.photo);
        this.getSignedUrlAWS(
          this.user_data.photo,
          this.user_data.photo,
          "download_object"
        );
      }
    });
  }
  nombre_archivo: string = "Subir foto...";
  file_object_: any;
  path_image;
  getSignedUrlAWS(original_name, pathFile, action): string {
    const aws = require("aws-sdk");

    const endpoint = new AWS.Endpoint(bucket.endpoint);
    let s3 = new AWS.S3({
      accessKeyId: bucket.accessKEY,
      secretAccessKey: bucket.secretKEY,
      endpoint: endpoint,
    });
    var params = {
      Bucket: "aion-ema-admin",
    };

    var uploadParams = { Bucket: params.Bucket, Key: "", Body: "" };
    var file = this.nombre_archivo; //this.upload_data.file_name;
    var fileStream = this.file_object_; //this.upload_data.file;//this.reader;//fs.createReadStream(file);
    uploadParams.Body = fileStream;
    this.path_image = "mantix/user/photo/" + pathFile;
    //var path = require('path');
    uploadParams.Key = this.path_image;
    if (action == "read_object") {
      s3.listObjects(params, function (err, data) {
        if (err) {
          console.log("Error:", err);
        } else {
          console.log("Success:", data);
        }
      });
    } else if (action == "create_object") {
      let this_ = this;
      this.uploadFileToBucket(s3, "", this.path_image, uploadParams).on(
        "httpUploadProgress",
        function (progress) {}
      );
    } else if (action == "download_object") {
      const params = {
        Bucket: "aion-ema-admin",
        Key: this.path_image,
      };
      let this_ = this;
      s3.getObject(params, function (err, data) {
        if (err) {
        }
        if (data) {
          var enc = new TextDecoder("utf-8");
          const data_ = new Uint8Array(data.Body as ArrayBuffer);

          this.blob = new Blob([data_]);
          var urlCreator = window.URL;

          //if (window.webkitURL !== undefined) {
          // ////console.log(window.webkitURL);
          urlCreator = window.URL; // || window.webkitURL;
          //  }

          var downloadURL = urlCreator.createObjectURL(this.blob);
          // var downloadURL = window.URL.createObjectURL(data.Body);
          //var link = document.createElement('a');

          this_.user_photo =
            this_.sanitizer.bypassSecurityTrustUrl(downloadURL);

          // link.href = downloadURL;
          //this_.user_profile_img ="data:image/jpeg;base64," +btoa(data_.toString());
          ////console.log(this_.user_profile_img);
          // link.download = original_name;
          // link.click();
        }
      });
    } else if (action == "delete_object") {
      const params = {
        Bucket: "aion-ema-admin",
        Key: pathFile,
      };
      let this_ = this;
      s3.deleteObject(params, function (err, data) {
        if (err) {
          ////console.log("File NOT deleted....... "+params.Key);
        }
        if (data) {
          ////console.log("File deleted....... "+params.Key);
          ////console.log(data);
        }
      });
    }
    /* const url = s3.getSignedUrl("getObject", {
        Bucket: bucket,
        Key: pathFile,
        Expires: 8000,
        //ResponseContentType: "application/pdf",
      });
  */

    return "url";
  }
  uploadFileToBucket = function (s3_, bucket, pathFile, parameters) {
    let this_ = this;
    return s3_.upload(parameters, function (err, data) {
      console.log("Subiendo................");
      if (err) {
        console.log("Error", err);
        return false;
      }
      if (data) {
        console.log("Upload Success", data.Location);
        /* this_.dcm_file_=null;
         this_.selected_file=false;
              this_.message_notification="";
              this_.reset();
              this_.nombre_archivo ="";
              this_.upload_data=null;
              this_.getFilesData();*/
        return true;
      }
    });
  };
  userProfile() {
    this.routes.navigate(["/user-profile"]);
  }
  ngAfterViewInit() {}
  reload() {
    location.reload();
  }
}
