import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import 'rxjs/add/operator/map'

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private httpClient: HttpClient) { }
  getCompany(company_id) {
    ////console.log("--- En servicio ---");
    return this.httpClient
    .get(environment.api.company+"/"+company_id
    ).map(data =>
    data);
  }
  getCompanyBranch(company_id,companyBranch_id){
    return this.httpClient
    .get(environment.api.companyBranches+"/"+companyBranch_id,{
        params:{
            company_id:company_id
        }
    }
    ).map(data =>
    data);
  }
  }

 

