import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import "rxjs/add/operator/map";
import { environment } from "src/environments/environment";
import { content } from "html2canvas/dist/types/css/property-descriptors/content";

@Injectable({
  providedIn: "root",
})
export class EmployeesService {
  constructor(private httpClient: HttpClient) {}
  getEMployeesByID(employeeID, keyword) {
    // console.log("Employee number: " +employeeID);
    return this.httpClient
      .get(environment.api.Employees_LAMBDA_V1 + "/" + employeeID, {
        params: {
          keyword: keyword,
        },
      })
      .map((data) => data);
  }
  upsertEmployee(data) {
    return this.httpClient
      .post(environment.api.upseremployee, data)
      .map((data) => data);
  }
  getDriverLicense(employeeID, licenses_id) {
    return this.httpClient
      .get(environment.api.driverLicenses + "/" + licenses_id, {
        params: {
          employee_id: employeeID,
        },
      })
      .map((data) => data);
  }
  upsertDriverLicense(data) {
    return this.httpClient
      .post(environment.api.driverLicenses, data)
      .map((data) => data);
  }
  getDocumentEmployees(employeeID) {
    return this.httpClient
      .get(environment.api.documentEmployees, {
        params: {
          employee_id: employeeID,
        },
      })
      .map((data) => data);
  }

  upsertDocument(data) {
    return this.httpClient
      .post(environment.api.documentEmployees, data)
      .map((data) => data);
  }
  /*
  AMH  23 02  2024
  Esta función ya no se utiliza y por lo tanto el endpoint en environment tampoco
  */
  obtenerEmpleados_DEPRECATED(employeeID, rs) {
    return this.httpClient
      .get(environment.api.employeesGet + employeeID)
      .map((data) => data);
  }
  obtenerEmpleado(employeeID) {
    return this.httpClient
      .get(
        environment.api.Employees_LAMBDA_V1 +
          "/" /*'http://adec-authorization.us-east-2.elasticbeanstalk.com/v1/ema-system/employees/'*/ +
          employeeID,
        {
          params: {
            keyword: null,
          },
        }
      )
      .map((data) => data);
  } /////////
}
