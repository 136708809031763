import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import 'rxjs/add/operator/map'

@Injectable({
  providedIn: 'root'
})
export class FileMapsService {

  constructor(private httpClient : HttpClient) { }
  /////////////////// DEVICES
  getFileForService(work_order_group, associated_service_id) {
    ///activity group
    return this.httpClient.get(environment.api.file_maps_service,{
      params:{
        work_order_group:work_order_group,
        associated_service_id:associated_service_id
      }
    }).map(data=>
      data);
    }
    upserFilesForService(file_data){
      return this.httpClient.post(environment.api.file_maps_service,
      file_data).map(data=>
       data);
    }
    deleteFileForService(sales_map_id:number) {
      return this.httpClient.delete(environment.api.file_maps_service+"/"+sales_map_id).map(data=>
      data);

    }

}
