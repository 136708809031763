import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import "rxjs/add/operator/map";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class NotificationEmailAndMessageService {
  constructor(private httpClient: HttpClient) {}
  sendMessageSMSEmail(user_data) {
    return this.httpClient
      .post(environment.api.notificationsNewPassword, user_data)
      .map((data) => data);
  }
  getUserInformation(user, meta_phone, meta_email) {
    return this.httpClient
      .get(
        environment.api.checkUserInformation +
          "?user_name=" +
          user +
          "&email=" +
          meta_email +
          "&phone=" +
          meta_phone
      )
      .map((data) => data);
  }
}
