import { Component, AfterViewInit, OnInit, Output, EventEmitter  } from '@angular/core';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [AuthService]
})
export class SidebarComponent implements OnInit {
  @Output() refreshBreadcrumb: EventEmitter<any> =  new EventEmitter();

  showMenu = '';
  showSubMenu = '';
  showsubmenu_ = false;
  username: string;
  branch_office_name: string;
  esEmpleado;
  user_name_prof: string;

  public sidebarnavItems: any[] = [];
  sidebarnavItemsTemp: any[];

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private service: AuthService
  ) {}

  // this is for the open close
  addExpandClass(element: any) {
     if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
     // this.showMenu = element;
  }
  addExpandClassSubmenu(element: any) {
    // //console.log(this.showsubmenu_)
    if (this.showsubmenu_){
      this.showSubMenu = '0';
      this.showsubmenu_=false;
    }else{
      this.showsubmenu_=true;
      this.showSubMenu = element.title;
    }
  /*  if (element === this.showSubMenu) {
     this.showSubMenu = '0';
   } else {
     this.showSubMenu = element;
   }*/
    // this.showMenu = element;
 }
  addActiveClass(menu: any, submenu: any) {
    this.showSubMenu = submenu.title;
    let data = {
      menu: menu,
      submenu: submenu,
    };
    localStorage.setItem('menu', menu.menu_id);
    localStorage.setItem('submenu', submenu.menu_id);
    this.refreshBreadcrumb.emit(data);
  }
  logout(e) {
    e.stopPropagation();
    e.preventDefault();
    this.service.logout().subscribe();
    return false;
  }
  // End open close
  ngOnInit() {
    this.sidebarnavItems = [];
    //esperando valor del localstorage
    //this.esEmpleado = localStorage.getItem('esEmpleado');
    this.sidebarnavItems = [];
    //obtener menu

    let menuTemp = JSON.parse(sessionStorage.getItem('menu'));

    this.sidebarnavItems = menuTemp.data;
   // //console.log("Obteniendo menu");
   /*

    for (let i=0; this.sidebarnavItems.length; i++){
      if (this.sidebarnavItems[i].class_==null || this.sidebarnavItems[i].class_==""){
        //console.log(this.sidebarnavItems[i].class);
        this.sidebarnavItems[i].class_ = this.sidebarnavItems[i].class_==null || this.sidebarnavItems[i].class_==""? "otro": this.sidebarnavItems[i].class_ ;
      }
    }
    this.sidebarnavItems= this.sidebarnavItems;*/
    ////console.log(this.sidebarnavItems);

    this.username = localStorage.getItem('user');

    this.user_name_prof = localStorage.getItem("user_name_prof");

    ////console.log("user name: " + this.user_name_prof);
    if (this.username.length != null)
    if (this.username.length > 23 ){
      this.username = this.username.substring(0, 23) + '...';
    }

    this.branch_office_name = localStorage.getItem('branch_office_name');
    this.showSubMenu = localStorage.getItem('titleMenu');
  }
}
