import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";

import { RouterModule, Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { environment } from "../../environments/environment";
import { emaUtils } from "../../app/AIONSystemsComponentsV1_0_0/ema-utils/documents-utils";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NotificationEmailAndMessageService } from "../services/notification-email-and-message.service";
import { EmployeesService } from "../services/employees.service";
import { CompanyService } from "../services/company.service";
import { companySecModel } from "../model/companySecModel";
// "./login-v10.component.css",
//"./login-v10.component.scss",
@Component({
  selector: "app-login-v10",
  templateUrl: "./login-v10.component-v1-0-0.html",
  styleUrls: [
    "./login-v10.component-v1-0-0.scss",
    "./login-v10.component.css",
    "./../component/ema-styles-v1/ema-forms-v1.scss",
    "./../component/ema-styles-v1/ema-modal.scss",
  ],
  providers: [
    AuthService,
    emaUtils,
    CompanyService,
    EmployeesService,
    NotificationEmailAndMessageService,
  ],
})
export class LoginV10Component implements OnInit {
  company_img =null;
    //"https://aionsystems.s3.us-west-2.amazonaws.com/mantix/E5zTrxmXEAEfsOb.jpg";
  msg = "";
  staticAlertClosedLogin = true;
  alertLogin: IAlert;
  development_indicator: String;
  hidden_denvironment_text: boolean;
  @ViewChild("changePasswordModal", { static: true })
  change_password_modal: ElementRef;
  version_str: string = environment.version_app;
  constructor(
    private tools: emaUtils,
    private authService: AuthService,
    private routes: Router,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private employee_service: EmployeesService,
    private company_service: CompanyService,
    private notificationService: NotificationEmailAndMessageService
  ) {
    ////console.log("Ambiente:", environment.ambiente);

    if (!environment.production) {
      this.development_indicator = environment.descrpcion_ambiente;
      this.hidden_denvironment_text = false;
    } else {
      this.development_indicator = "";
      this.hidden_denvironment_text = false;
    }
    this.alertLogin = {
      id: 1,
      type: "danger",
      message: "Se requiere seleccionar todos los campos..",
    };
  }

  loginform = true;
  recoverform = false;
  ennableInputs = false;
  ennableSpinner = true;

  showRecoverForm() {
    this.loginform = !this.loginform;
    this.recoverform = !this.recoverform;
  }
  company_identification: string = "";
  async getURLInformation() {
    let resp = new Promise(async (resolve, reject) => {
      this.route.paramMap.subscribe((params) => {
        this.company_identification = params.get("companyToken");
        // console.log("--->>>>>>>>> " + this.company_identification);
        localStorage.setItem("comp_login_token", this.company_identification);
        resolve("Ok.");
      });
    });
    return await resp;
  }
  async loadData() {
    await this.getURLInformation();
    console.log("Company ID:", this.company_identification);
   
   
    //consultar la api de companies mediante token --
    await this.getCompanySecurityInfo();
  }
  ngOnInit() {
    this.loadData();

    let session_active = localStorage.getItem("session_active_admin");

    if (session_active == "inactive" || session_active == null) {
      this.routes.navigate([
        "/login/" + localStorage.getItem("comp_login_token"),
      ]);
    } else {
      this.check(
        localStorage.getItem("username_enc_admin"),
        localStorage.getItem("psw_enc_admin")
      );
    }
  }
  checkLogginActive() {
    let session_active = localStorage.getItem("session_active_admin");
    if (session_active == "active") {
      this.check(
        localStorage.getItem("username_enc_admin"),
        localStorage.getItem("psw_enc_admin")
      );
    }
  }
  changePasswordModalFnc() {
    this.modalService.open(this.change_password_modal);
  }
  company_id_: number;
  company_branch_id_: number;
  getCompanyBranchData() {
    this.company_service
      .getCompanyBranch(this.company_id_, this.company_branch_id_)
      .subscribe((res: any) => {
        localStorage.setItem(
          "company-branch-data",
          JSON.stringify(res.body[0])
        );
      });
  }
  getEmployeesByID_(employee_id) {
    this.employee_service
      .getEMployeesByID(employee_id, null)
      .subscribe((resp: any) => {
        console.log("--------------->>", resp);
        this.company_id_ = resp.data[0].company_id;
        this.company_branch_id_ = resp.data[0].company_branch_id;
        let json_employee_data = resp.data[0];
        localStorage.setItem(
          "employee-data",
          JSON.stringify(json_employee_data)
        );
        this.getCompanyBranchData();
      });
  }
  check(uname: string, p: string) {
    this.tools.cleanCache();
    localStorage.setItem("username_enc_admin", uname);
    localStorage.setItem("psw_enc_admin", p);
    console.log("this.company_identification ", this.company_identification);
    localStorage.setItem("comp_login_token", this.company_identification);
    //Validar
    // debugger;
    this.ennableInputs = true;
    this.ennableSpinner = false;
    this.staticAlertClosedLogin = true;
    //console.log("------------------> " + uname);
    if (uname.length > 0 && p.length > 0) {
      this.authService.login(uname, p).subscribe(
        (res: any) => {
          this.msg = "";
          console.log("login -->>>> ", res);
          if (res.api_code != "200") {
            this.staticAlertClosedLogin = false;
            this.alertLogin.type = "danger";
            this.alertLogin.message = "Error de login.";
            localStorage.setItem("session_active_admin", "inactive");

            this.resetLogin();
            this.ennableSpinner = true;
            this.ennableInputs = false;
          } else if(res.data[0].company_id==this.companyinfo.company_id){
            //console.log( "------------------res.data");
            //console.log( res.data);
            //localStorage.setItem("user_id",res.data.user_data.user_id);
            localStorage.setItem("user_id", res.data[0].users_id);
            //console.log(res.data[0].users_id);
            //localStorage.setItem("employee_number", res.data.user_data.user_detail.employee_number+"");
            localStorage.setItem(
              "employee_number",
              res.data[0].employee_number
            );
            this.getEmployeesByID_(res.employee[0].employee_identity);
            this.authService.obtenerMenu().subscribe((respMenu: any) => {
              //buscar url default
              //console.log(respMenu)
              let i = "";
              for (let itemMenu of respMenu.body) {
                for (let itemSubMenu of itemMenu.submenu) {
                  if (itemSubMenu.is_default_page == 1) {
                    i = itemSubMenu.path;
                    ////console.log("Se encontro default " + i);
                  }
                }
              }

              if (i.length == 0) {
                i = "/home";
              }
              ////console.log('URL default:', i);
              i = "/home";
              //console.log("routing to "  + i);

              this.routes.navigate([i]);
              ////console.log("Error al consultar el menu -->",err);
            });
          
          }else{
            this.staticAlertClosedLogin = false;
            this.alertLogin.type = "danger";
            this.alertLogin.message = "Error de login.";
            localStorage.setItem("session_active_admin", "inactive");

            this.resetLogin();
            this.ennableSpinner = true;
            this.ennableInputs = false;
          }
        },
        (err: any) => {
          this.staticAlertClosedLogin = false;
          this.alertLogin.type = "danger";
          this.alertLogin.message = "Usuario o contraseña incorrecto.";
          this.ennableSpinner = true;
          this.ennableInputs = false;
          this.resetLogin();
        }
      );
    } else {
      this.staticAlertClosedLogin = false;
      this.alertLogin.type = "danger";
      this.alertLogin.message = "Se requiere usuario y contraseña.";
      this.resetLogin();
      this.ennableSpinner = true;
      this.ennableInputs = false;
    }
  }
  user_data = {} as userData;
  email: string; //"ejemplo@gmail.com"   <---CUALQUIER CORREO
  email_phone_for_password: string;
  notificatoions: string;
  user_for_password: string;
  email_phone_option: string;

  checkIfPhoneOrEmail(txt: string) {
    let user_name = this.user_for_password;
    let phone_ = "";
    let email_ = "";
    if (txt.includes("@")) {
      email_ = this.email_phone_for_password;
    } else {
      phone_ = this.email_phone_for_password;
    }
    this.notificationService
      .getUserInformation(user_name, phone_, email_)
      .subscribe((res: any) => {
        console.log(res);
      });
  }
  getUserInfo(user: string, meta: string) {
    this.notificationService
      .sendMessageSMSEmail(this.user_data)
      .subscribe((res: any) => {});
  }
  sendMessage() {
    this.notificationService
      .sendMessageSMSEmail(this.user_data)
      .subscribe((res: any) => {});
  }
  resetLogin() {
    setTimeout(() => {
      this.staticAlertClosedLogin = true;
    }, 1500);
  }
  companyinfo={} as companySecModel;
async getCompanySecurityInfo(){
  console.log("entrando")
  this.authService.getCompanyByToken(this.company_identification).subscribe((res:any)=>{
if(res.body!=null){
this.companyinfo=res.body[0];
console.log("Company_info")
localStorage.setItem("company_info",JSON.stringify(this.companyinfo));
this.company_img=this.companyinfo.company_main_logo;
}
  })

}



}

export interface IAlert {
  id: number;
  type: string;
  message: string;
}
export class userData {
  subject: string; //"Restablecimiento de contraseña", <---CUALQUIER ASUNTO
  mensaje: string; //"Restablecimiento de contraseña", <---CUALQUIER MENSAJE
  send_sms: number; //0,            <---0 NO ENVIA SMS, 1 ENVIA SMS
  send_email: number; //1,            <---0 NO ENVIA EMAIL, 1 ENVIA EMAIL
  mobile_num: string; //"000000000",    <---NUMERO DE 10 DIGITOS
  email: string; //"ejemplo@gmail.com"   <---CUALQUIER CORREO
}
