

export const bucket={
endpoint:"d2s4.or4.idrivee2-60.com",
accessKEY:"UCHsuL7k6Aot4baiANJG",
secretKEY:"qOo3gO3b4UfXpT6sVyIg4puEIvMsfeYB3PeFMq0X",
}
export const SES={
    accesKey:"AKIAZI5VJPNY3FWCZSNA",
    secretKey:'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',
}