import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'ema-forms',
  templateUrl: './ema-forms.component.html',
  styleUrls: ['./ema-forms.component.css', './ema-forms.component.scss']
})
export class EmaFormsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.seetingsForTheMultiSelect={
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "Seleccionar Todos",
      unSelectAllText: "Deseleccionar Todos",
      itemsShowLimit: 1,
      allowSearchFilter: true,
    }
    this.dataArray.push({id:1, name:"Uno"});
    this.dataArray.push({id:2, name:"Dos"});
    this.dataArray.push({id:3, name:"Tres"});
  }
  dataArray = [];
  modelForTheRecordSelected=[];
  seetingsForTheMultiSelect:IDropdownSettings;

  onItemSelect_Data(event){

  }
  onSelectAll_Data(event){

  }
  onItemDeSelect_Data(event){

  }
  onDeSelectAll_Data(event){

  }
}
