import * as $ from "jquery";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { LOCALE_ID, NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { Routes, RouterModule } from "@angular/router";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { FullComponent } from "./layouts/full/full.component";
import { BlankComponent } from "./layouts/blank/blank.component";

import { NavigationComponent } from "./shared/header-navigation/navigation.component";
import { SidebarComponent } from "./shared/sidebar/sidebar.component";
import { BreadcrumbComponent } from "./shared/breadcrumb/breadcrumb.component";

import { Approutes } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthGuard } from "./auth.guard";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { BasicAuthInterceptor } from "./factory/interceptor";
import { AuthService } from "./services/auth.service";

////MAterial
import { MatExpansionModule } from "@angular/material/expansion";
import { LoginV10Component } from "./login-v10/login-v10.component";
import { SharedModuleModule } from "./shared-module/shared-module.module";
import { AccesoDenegadoComponent } from "./acceso-denegado/acceso-denegado.component";

import { registerLocaleData } from "@angular/common";
import localeEn from "@angular/common/locales/en";
import localeEsAr from "@angular/common/locales/es-AR";
import localePy from "@angular/common/locales/es-PY";
import { SystemConfigurationUsersListComponent } from "./AIONSystemsComponentsV1_0_0/system-configuration/system-configuration-users/system-configuration-users-list/system-configuration-users-list.component";
import { EmaSelectSearchComponent } from "./AIONSystemsComponentsV1_0_0/ema-select-search/ema-select-search.component";

//import { PruebaComponent } from "./component/monitoring-reports-temp-url/test.component";
//import { MonitoringReportsComponent } from "./component/monitoring-reports-temp-url/monitoring-reports.component";

registerLocaleData(localePy, "es");
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20,
};

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,

    LoginV10Component,
    AccesoDenegadoComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    RouterModule.forRoot(Approutes),
    PerfectScrollbarModule,
    MatExpansionModule,
    SharedModuleModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicAuthInterceptor,
      multi: true,
    },
    AuthGuard,
    AuthService,
    { provide: LOCALE_ID, useValue: "es-Ar" },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
