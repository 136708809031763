import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { DragDropModule, DragDropRegistry } from "@angular/cdk/drag-drop";
import {
  ScrollingModule,
  ViewportRuler,
  ScrollDispatcher,
} from "@angular/cdk/scrolling";
import { PlatformModule, Platform } from "@angular/cdk/platform";
import { SpinnerComponent } from "src/app/shared/spinner.component";
import { EmaSpinnerComponent } from "../AIONSystemsComponentsV1_0_0/ema-spinner/ema-spinner.component";
import { EmaToolbarComponent } from "../AIONSystemsComponentsV1_0_0/ema-toolbar/ema-toolbar.component";
import { EmaFormsComponent } from "../AIONSystemsComponentsV1_0_0/ema-forms/ema-forms.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import {
  NgbPaginationModule,
  NgbAlertModule,
} from "@ng-bootstrap/ng-bootstrap";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { RolesAdminComponent } from "../component/system-admin/roles-admin/roles-admin.component";
import { UserAdminComponent } from "../component/system-admin/user-admin/user-admin.component";
import { EmaSelectSearchComponent } from "../AIONSystemsComponentsV1_0_0/ema-select-search/ema-select-search.component";

@NgModule({
  declarations: [
    SpinnerComponent,
    EmaToolbarComponent,
    EmaFormsComponent,
    EmaSpinnerComponent,
    RolesAdminComponent,
    UserAdminComponent,
    EmaSelectSearchComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    ScrollingModule,
    PlatformModule,
    NgMultiSelectDropDownModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgbModule,
  ],
  exports: [
    SpinnerComponent,
    EmaSpinnerComponent,
    EmaToolbarComponent,
    EmaFormsComponent,
    RolesAdminComponent,
    UserAdminComponent,
    EmaSelectSearchComponent,
  ],
  providers: [ViewportRuler, , Platform, DragDropRegistry, ScrollDispatcher],
})
export class SharedModuleModule {}
